<template>
  <div class="mobile-navbar-new">
    <div class="mobile-navbar-new__header">
      <div class="mobile-navbar-new__header--logo-box" @click="redirectToDiscover()">
        <img src="@/assets/images/logo@2x.webp" alt="Glocal Logo" />
      </div>
      <div class="mobile-navbar-new__header--search-bar">
        <navbar-search></navbar-search>
      </div>
      <div
        class="mobile-navbar-new__header--hamburger"
        @click="mobileNavbar"
        :class="{ 'is-active': isActive }"
      >
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </div>
    </div>
    <Transition name="slide-fade">
      <div class="mobile-navbar-new__list-menu" v-if="menu">
        <div class="mobile-navbar-new__list-menu--header">
          <h2 class="title">
            Glocal Menu
          </h2>
          <div class="profile-picture">
            <img
              :src="displayPicture"
              alt="user"
              class="navigation-icon icon-account"
            />
          </div>
        </div>
        <div class="mobile-navbar-new__list-menu--body">
          <ul>
            <li>
              <a @click="redirectToProfile">
                <i class="icon icon-account_circle"></i>
                Your Profile
              </a>
            </li>
            <li>
              <a @click="redirectToFolders()">
                <img src="@/assets/icons/folder-icon-black.svg" alt="icon" />
                Workspace
              </a>
            </li>
            <li>
              <a @click="redirectToFeed()">
                <img src="@/assets/icons/feed-icon-black.png" alt="icon" class="feed-icon" />
                Feed
              </a>
            </li>
            <!-- <li>
              <a @click="redirectToGlocalAI()">
                <img src="@/assets/icons/glocal-ai-icon-navbar-black.svg" alt="icon" class="glocal-ai-icon"/>
                Glocal AI
              </a>
            </li> -->
            <li>
              <a @click="showNotifications()">
                <img src="@/assets/icons/notifications-icon-black.svg" alt="icon"/>
                Alerts
              </a>
            </li>
            <!-- <li>
              <a @click="openPortfolioDrawer()">
                <img src="@/assets/icons/portfolio-black.svg" alt="icon" />
                Portfolio
              </a>
            </li> -->
            <li>
              <a @click="openNetworks()">
                <img src="@/assets/icons/diversity-icon-black.png" alt="icon"/>
                Networks
              </a>
            </li>
            <li>
              <a @click="redirectToDiscover()">
                <img src="@/assets/icons/discover_icon.png" alt="icon" />
                Discover
              </a>
            </li>
            <li>
              <a @click="redirectToSettings">
                <i class="icon icon-settings_filled"></i>
                Settings
              </a>
            </li>
            <li>
              <a @click="logout">
                <img src="@/assets/icons/logout.svg" alt="icon" class="logout-icon">
                Logout
              </a>
            </li>
          </ul>
          <button @click="openGlocalInvitationModal()" class="btn">
            <img src="@/assets/icons/forward_to_inbox.svg" alt="icon" /> Invite
            a contact
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import NavbarSearch from "../BaseComponents/NavbarSearch.vue";
import Cookies from "js-cookie";
import {mapActions, mapGetters} from 'vuex'

export default {
  components: {
    NavbarSearch,
  },
  data() {
    return {
      menu: false,
      isActive: false,
    };
  },
  computed: {
    ...mapGetters({
      authUserInformation: 'profile/userInformation',
    }),
    displayPicture(){
      const picture = this.$store.getters["profile/userInformation"];
      return Object.keys(picture).includes('profile') ? picture.profile.profile_picture : "";
    },
    getUserInfo(){
      return this.$store.getters["profile/getSelectedUserProfile"];
    },
    userId(){
      return this.$store.getters["profile/currentLoginUser"]
    },
  },
  methods: {
    ...mapActions({
      setActiveTabKey: 'profile/setActiveTabKey',
      setSelectedUser: 'profile/setSelectedUser'
    }),
    mobileNavbar() {
      this.menu = !this.menu;
      this.isActive = !this.isActive;
    },
    showNotifications() {
      this.emitter.emit('showNotificationsDrawer');
    },
    openPortfolioDrawer() {
      this.emitter.emit('showPorfolioDrawer');
    },
    openNetworks(){
      this.setActiveTabKey('network')
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/`,
      });
      // this.getUserInformation()
      this.menu = false;
      this.isActive = false;
    },
    async getUserInformation() {
      try {
        await this.$store.dispatch("profile/fetchUserProfile");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        await this.$store.dispatch("profile/fetchPhotoResume");
        
      } catch (err) {
        console.log(err);
      }
    },
    openGlocalInvitationModal() {
      this.emitter.emit('showGlocalInvitationModal');
    },
    redirectToProfile() {
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/`,
      });
      // this.getUserInformation()
      this.menu = false;
      this.isActive = false;
    },
    redirectToFolders() {
      this.$router.push('/workspaces/public/');
      this.menu = false;
      this.isActive = false;
    },
    redirectToFeed() {
      this.$router.push('/network-feed');
      this.menu = false;
      this.isActive = false;
    },
    redirectToGlocalAI() {
      this.$router.push('/glocal-AI');
      this.menu = false;
      this.isActive = false;
    },
    redirectToDiscover() {
      this.$router.push(`/discover`);
      this.menu = false;
      this.isActive = false;
    },
    redirectToSettings(){
      this.$router.push(`/user/${this.userId}/settings`);
    },
    async logout(){
      try{
        const accessToken = Cookies.get('access_token')
        Cookies.remove("access_token");
        this.$router.replace('/');
        this.$store.dispatch('auth/logout', accessToken)
        .then(()=>{
          Cookies.remove("refresh_token");
          Cookies.remove("user_id");
          Cookies.remove("interests")
          Cookies.remove("user")
        })
      }
      catch(err){
        console.log(err.response.data);
      }
    },
  },
};
</script>
<style lang="scss">
.mobile-navbar-new {
  padding: 3rem 3rem;
  background-color: $color-black;
  position: fixed;
  z-index: 444;
  width: 100%;
  top: 0;
  &__header {
    display: flex;
    align-items: center;
    &--logo-box {
      width: 4rem;
      margin-right: 3rem;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &--search-bar {
      width: 100%;
      margin-right: 3rem;
      .search-filter {
        width: 100%;
        margin: 0;
        &__header {
          height: 4.9rem;
          .icon {
            font-size: 1.8rem;
          }
          .nav-search {
            font-size: 1.8rem;
          }
        }
      }
    }
    &--hamburger {
      margin-left: auto;
      cursor: pointer;
      .line {
        width: 20px;
        height: 2px;
        background-color: $color-white;
        display: block;
        margin: 4px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
    &--hamburger.is-active .line:nth-child(2) {
      opacity: 0;
    }

    &--hamburger.is-active .line:nth-child(1) {
      -webkit-transform: translateY(7px) rotate(45deg);
      -ms-transform: translateY(7px) rotate(45deg);
      -o-transform: translateY(7px) rotate(45deg);
      transform: translateY(7px) rotate(45deg);
    }

    &--hamburger.is-active .line:nth-child(3) {
      -webkit-transform: translateY(-5px) rotate(-45deg);
      -ms-transform: translateY(-5px) rotate(-45deg);
      -o-transform: translateY(-5px) rotate(-45deg);
      transform: translateY(-5px) rotate(-45deg);
    }
  }
  &__list-menu {
    position: fixed;
    background-color: $color-white;
    width: 100%;
    left: 0;
    top: 10.8rem;
    height: 100vh;
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.165);
      .profile-picture {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-faded-blue;
        border-radius: 100%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
      .title {
        color: $color-black;
        font-size: 2.5rem;
        font-family: $font-primary-bold;
        margin-bottom: 0;
        position: relative;
        &::after {
          content: "";
          width: 60%;
          height: 0.4rem;
          background-color: $color-primary;
          position: absolute;
          bottom: -0.5rem;
          left: 0;
          opacity: 1;
        }
      }
    }
    &--body {
      padding: 4rem 2.4rem;
      height: 100%;
      ul {
        max-height: calc(100vh - 40rem);
        overflow-y: auto;
        margin-bottom: 4rem;
        overflow-x: hidden;
        li {
          padding: 0;
          list-style: none;
          margin-bottom: 4.5rem;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            display: flex;
            align-items: center;
            width: fit-content;
            color: $color-black;
            font-size: 2.2rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: normal;
            img {
              width: 2.6rem;
              height: auto;
              margin-right: 2.7rem;
            }
            .icon {
              margin-right: 2.7rem;
              font-size: 3rem;
              color: $color-black;
            }
            .feed-icon {
              width: 3rem;
            }
            // .glocal-ai-icon {
            //   width: 3rem;
            //   margin-right: 2rem;
            // }
            .logout-icon {
              width: 3rem;
              margin-left: 0.3rem;
            }
          }
        }
        &::-webkit-scrollbar {
          width: 0.4rem;
          height: 0.4rem;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 0.8rem;
          width: 0.4rem;
          height: 0.4rem;
        }
      }
      .btn {
        background-color: $color-primary;
        width: 100%;
        border: none;
        outline: none;
        height: 7rem;
        font-size: 2.2rem;
        font-family: $font-primary-medium;
        color: $color-white;
        cursor: pointer;
        img {
          margin-right: 1rem;
          width: 3rem;
        }
      }
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  transform: translateX(-100%);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
